import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import Header from "./Header";
// import Footer from "./Footer";
import "normalize.css";

function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <div>
        <Header />
        <main>{children}</main>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Layout;
