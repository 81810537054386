import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 5vw;
  padding-right: 5vw;

  nav {
    ul {
      display: flex;
      gap: 3rem;
      list-style: none;
      li {
        a {
          color: var(--grey);
          text-decoration: none;
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
  }
`;

const StyledHeading = styled.h1`
  font-size: 2.5rem;
  a {
    color: var(--white);
    text-decoration: none;
  }
`;

const StyledCircleButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border: 1px solid var(--grey);
`;

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: var(--primaryDark);
    color: var(--white);
    span {
      font-size: 1.5rem;
    }
    .MuiButtonBase-root {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
`;

function Header() {
  const [state, setState] = useState({
    right: false,
  });
  const isMobileMatch = useMediaQuery("(max-width:600px)");
  const navAnchor = "right";
  const navLinks = [
    {
      name: "Projects",
      url: "/projects",
    },
    {
      name: "About",
      url: "/about",
    },
    {
      name: "Blog",
      url: "/blog",
    },
    {
      name: "Uses",
      url: "/uses",
    },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[{ name: "Home", url: "/" }, ...navLinks].map((link, index) => (
          <>
            <ListItem
              button
              key={link.name}
              onClick={() => {
                window.location.href = link.url;
              }}
            >
              <ListItemText primary={link.name} />
            </ListItem>
            <Divider
              style={{
                backgroundColor: "var(--grey)",
              }}
            />
          </>
        ))}
      </List>
    </Box>
  );

  const renderLink = (link, index) => {
    return (
      <motion.a
        key={index}
        href={link.url}
        whileHover={{
          color: "var(--white)",
        }}
      >
        {link.name}
      </motion.a>
    );
  };

  return (
    <StyledHeader>
      <StyledHeading>
        {renderLink({ name: "Shehbaz Jafri", url: "/" }, 0)}
      </StyledHeading>
      <nav>
        {!isMobileMatch && (
          <ul>
            {navLinks.map((link, index) => (
              <li>{renderLink(link, index)}</li>
            ))}
          </ul>
        )}
        {isMobileMatch && (
          <>
            <StyledCircleButton onClick={toggleDrawer(navAnchor, true)}>
              <FontAwesomeIcon icon={faBars} />
            </StyledCircleButton>
            <StyledDrawer
              anchor={navAnchor}
              open={state[navAnchor]}
              onClose={toggleDrawer(navAnchor, false)}
              style={{
                backgroundColor: "var(--black)",
              }}
            >
              {list(navAnchor)}
            </StyledDrawer>
          </>
        )}
      </nav>
    </StyledHeader>
  );
}

export default Header;
